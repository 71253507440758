<template>
  <header class="flex h-12 bg-[#1046a0] px-8 dark:bg-black dark:text-white">
    <div class="flex h-full flex-none items-center">
      <img src="~/assets/images/logo.png" class="h-6" />
    </div>
    <div class="ml-5 flex flex-1 items-center justify-end gap-4">
      <IconField icon-position="left" class="basis-[600px]">
        <InputIcon>
          <Icon name="mdi:bullhorn-variant-outline" />
        </InputIcon>
        <AppNoticeTicker />
      </IconField>
    </div>
    <div class="flex flex-none items-center gap-4 px-4 py-2">
      <AppScreenSearch class="w-90" />
      <Icon v-tooltip.bottom="'회원정보 조회'" name="mdi:user" class="cursor-pointer text-white" size="1.5rem" @click="openUserInfo()" />
      <Icon v-tooltip.bottom="'로그아웃'" name="mdi:logout" class="cursor-pointer text-white" size="1.5rem" @click="logout()" />
    </div>
  </header>
</template>

<script setup>
const { createPanel } = useWindowStore();
const screenList = useScreenList();
const { logout } = useAuth();

const openUserInfo = () => {
  const menu = screenList.value.find(({ id }) => id === 'U10101');
  createPanel(menu);
};
</script>
